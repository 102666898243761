export const QUALIFICATIONS_LIST = [
  {
    id: 1,
    name: "Старт",
    code: "start",
  },
  {
    id: 2,
    name: "Эксперт по продажам",
    code: "expert",
  },
  {
    id: 3,
    name: "Менеджер по продажам",
    code: "star",
  },
  {
    id: 4,
    name: "Лидер продаж",
    code: "double_star",
  },
  {
    id: 5,
    name: "Тимлидер продаж",
    code: "diamond",
  },
  {
    id: 6,
    name: "Топ-лидер продаж",
    code: "double_diamond",
  },
  {
    id: 7,
    name: "Директор по продажам",
    code: "grand_diamond",
  },
  {
    id: 8,
    name: "Директор по продажам международного уровня",
    code: "diamond_elite",
  },
  {
    id: 9,
    name: "Амбассадор",
    code: "ambassador",
  },
  {
    id: 10,
    name: "Вице-президент дистрибьюторской сети",
    code: "v_president",
  },
];
