var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rating-distributors default-content-padding"},[_c('RatingDistributorFilters',{staticClass:"rating-distributors__filters",on:{"updateRating":_vm.updateRating}}),(_vm.rating)?_c('RatingDistributorsTop',{staticClass:"rating-distributors__top",attrs:{"date":_vm.rating.last_refreshed_at}}):_vm._e(),(_vm.isLoading)?_c('MainLoader'):_vm._e(),(!_vm.isLoading && _vm.getDistributors(_vm.countOfDistributors).length !== 0)?_c('div',{staticClass:"rating-distributors__list"},[_vm._l((_vm.getDistributors(_vm.countOfDistributors)),function(distributor,index){return _c('DistributorCard',{key:distributor.distributor_id,attrs:{"distributor":distributor,"index":index},on:{"showPersonCard":_vm.showPersonCard,"hidePersonCard":_vm.hidePersonCard}})}),(
        _vm.ratingType === 'invitingTsp' &&
        !!_vm.placeNumber &&
        _vm.placeNumber > 25 &&
        _vm.getDistributors(_vm.countOfDistributors).length > 3
      )?_c('MainObserver',{on:{"intersect":_vm.changePosition,"notIntersect":function($event){return _vm.changePosition('bottom')}}}):_vm._e()],2):_vm._e(),(
      _vm.getDistributors(_vm.countOfDistributors).length === 0 &&
      !_vm.isLoading &&
      _vm.rating !== null
    )?_c('p',{staticClass:"rating-distributors__notice"},[_vm._v(" По выбранным фильтрам дистрибьюторы не найдены ")]):_vm._e(),_c('DistributorRatingCard',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowPersonCard),expression:"isShowPersonCard"}],attrs:{"position":_vm.position,"number":_vm.placeNumber}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }