<script>
import { QUALIFICATIONS_LIST } from "@/package/const/qualifications-list";
import MainSelect from "@/components/helpers/MainSelect.vue";
import MainButton from "@/components/helpers/MainButton.vue";

const DEFAULT_PARAMS = {
  type: {
    id: "all",
    value: "all",
    name: "За все время",
  },
  qualification_code: {
    id: 99,
    name: "Все квалификации",
    code: "",
  },
  country: {
    id: "all",
    value: "",
    name: "Все страны",
  },
};

const DEFAULT_TSP_PARAMS = {
  type: {
    id: "all",
    value: "all",
    name: "За все время",
  },
  country: {
    id: "all",
    value: "",
    name: "Все страны",
  },
  company_tariff_code: {
    id: "all",
    value: "",
    name: "Все тарифы",
  },
};

export default {
  components: { MainButton, MainSelect },

  data() {
    return {
      params: {
        type: {
          id: "all",
          value: "all",
          name: "За все время",
        },
        qualification_code: {
          id: 99,
          name: "Все квалификации",
          code: "",
        },
        country: {
          id: "all",
          value: "",
          name: "Все страны",
        },
      },

      tspParams: {
        type: {
          id: "all",
          value: "all",
          name: "За все время",
        },
        country: {
          id: "all",
          value: "",
          name: "Все страны",
        },
        company_tariff_code: {
          id: "all",
          value: "",
          name: "Все тарифы",
        },
      },

      periods: [
        {
          id: "all",
          value: "all",
          name: "За все время",
        },
        {
          id: "month",
          value: "month",
          name: "За текущий месяц",
        },
        {
          id: "previous",
          value: "previous",
          name: "За прошлый месяц",
        },
        {
          id: "year",
          value: "year",
          name: "За текущий год",
        },
      ],
      countries: [
        {
          id: "all",
          value: "",
          name: "Все страны",
        },
        {
          id: "ru",
          value: "Россия",
          name: "Россия",
        },
        {
          id: "kz",
          value: "Казахстан",
          name: "Казахстан",
        },
      ],
      formattedQualificationsList: [
        {
          id: 99,
          name: "Все квалификации",
          code: "",
        },
        ...QUALIFICATIONS_LIST.reverse(),
      ],

      tariffsList: [
        {
          id: "all",
          value: "",
          name: "Все тарифы",
        },
        {
          id: "lite_p",
          value: "lite_p",
          name: "Лайт",
        },
        {
          id: "promo_p",
          value: "promo_p",
          name: "Промо",
        },
        {
          id: "pluspro_p",
          value: "pluspro_p",
          name: "Про+",
        },
      ],

      ratingType: "earnings",
    };
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.$emit("updateRating", this.params, this.ratingType);
      },
    },

    tspParams: {
      deep: true,
      handler() {
        this.$emit("updateRating", this.tspParams, this.ratingType);
      },
    },

    ratingType(newValue) {
      let params = newValue === "earnings" ? this.params : this.tspParams;
      this.$emit("updateRating", params, newValue);
    },
  },

  computed: {
    isShowResetButton() {
      return JSON.stringify(DEFAULT_PARAMS) !== JSON.stringify(this.params);
    },

    isShowTSPResetButton() {
      return (
        JSON.stringify(DEFAULT_TSP_PARAMS) !== JSON.stringify(this.tspParams)
      );
    },
  },

  methods: {
    setDefaultParams() {
      this.params = JSON.parse(JSON.stringify(DEFAULT_PARAMS));
    },

    setTSPDefaultParams() {
      this.tspParams = JSON.parse(JSON.stringify(DEFAULT_TSP_PARAMS));
    },
  },

  mounted() {
    this.$emit("updateRating", this.params, this.ratingType);
  },
};
</script>

<template>
  <div class="rating-distributors-filters">
    <div class="rating-distributors-filters__switch switch">
      <button
        class="switch__button"
        type="button"
        :class="{ switch__button_active: ratingType === 'earnings' }"
        @click="ratingType = 'earnings'"
      >
        Заработок
      </button>

      <button
        class="switch__button"
        type="button"
        :class="{ switch__button_active: ratingType === 'invitingTsp' }"
        @click="ratingType = 'invitingTsp'"
      >
        Подключенные ТСП
      </button>
    </div>

    <div
      v-if="ratingType === 'earnings'"
      class="rating-distributors-filters__wrapper"
    >
      <MainSelect
        class="rating-distributors-filters__selector"
        :selected-option.sync="params.type"
        :options="periods"
        :style-type="'background-white'"
      />

      <MainSelect
        class="rating-distributors-filters__selector"
        :selected-option.sync="params.qualification_code"
        :options="formattedQualificationsList"
        :style-type="'background-white'"
      />

      <MainSelect
        class="rating-distributors-filters__selector"
        :selected-option.sync="params.country"
        :options="countries"
        :style-type="'background-white'"
      />
    </div>

    <div
      v-if="ratingType === 'invitingTsp'"
      class="rating-distributors-filters__wrapper"
    >
      <MainSelect
        class="rating-distributors-filters__selector"
        :selected-option.sync="tspParams.type"
        :options="periods"
        :style-type="'background-white'"
      />

      <MainSelect
        v-if="ratingType === 'invitingTsp'"
        class="rating-distributors-filters__selector"
        :selected-option.sync="tspParams.company_tariff_code"
        :options="tariffsList"
        :style-type="'background-white'"
      />

      <MainSelect
        class="rating-distributors-filters__selector"
        :selected-option.sync="tspParams.country"
        :options="countries"
        :style-type="'background-white'"
      />
    </div>

    <MainButton
      v-if="isShowResetButton && ratingType === 'earnings'"
      class="rating-distributors-filters__reset-button"
      :title="'Сбросить фильтры'"
      :color="'blue'"
      @click="setDefaultParams"
    />

    <MainButton
      v-if="isShowTSPResetButton && ratingType === 'invitingTsp'"
      class="rating-distributors-filters__reset-button"
      :title="'Сбросить фильтры'"
      :color="'blue'"
      @click="setTSPDefaultParams"
    />
  </div>
</template>

<style lang="scss" scoped>
.rating-distributors-filters {
  .switch {
    width: 100%;
    display: flex;
    gap: 0;
    margin-bottom: 32px;

    &__button {
      padding: 8px 16px;
      @include text-2;
      color: $dark-primary;
      background: $light-primary;
      border: none;
      width: 50%;
      height: 40px;
      transition: all 0.3s ease;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &_active {
        background: $dark-primary !important;
        color: $light-primary !important;
      }

      &:first-of-type {
        border-radius: 8px 0 0 8px;
      }

      &:last-of-type {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  & ::v-deep .main-select {
    min-width: 100% !important;
  }

  & ::v-deep .main-select_background-white .main-select__items {
    max-width: none;
  }

  &__reset-button {
    margin-top: 16px;
  }

  &__selector {
    width: 100%;
  }
}

@media (min-width: 900px) {
  .rating-distributors-filters {
    &__wrapper {
      flex-direction: row;
    }
  }
}
</style>
