<script>
import { format } from "date-fns";

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      return format(new Date(this.date), "dd.MM.yyyy, HH:mm");
    },
  },
};
</script>

<template>
  <div class="rating-distributors-top">
    <p class="rating-distributors-top__last-update">
      Последнее обновление: {{ formattedDate }}
    </p>
    <p class="rating-distributors-top__info">
      Рейтинг обновляется каждые 3 часа
    </p>

    <!--    <DistributorCard
      class="rating-distributors-top__card"
      :personal-card="true"
    />-->
  </div>
</template>

<style lang="scss" scoped>
.rating-distributors-top {
  &__last-update {
    @include text-2;
    color: $dark-primary;
    margin-bottom: 4px;
  }

  &__info {
    @include caption-1;
    color: $dark-fifth;
  }

  &__card {
    margin-top: 16px;
  }
}
</style>
