<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { debounce } from "throttle-debounce";
import DistributorCard from "@/components/rating-distributors/DistributorCard.vue";
import RatingDistributorFilters from "@/components/rating-distributors/RatingDistributorsFilters.vue";
import RatingDistributorsTop from "@/components/rating-distributors/RatingDistributorsTop.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import DistributorRatingCard from "@/components/rating-distributors/DistributorRatingCard.vue";
import MainObserver from "@/components/helpers/MainObserver.vue";

export default {
  name: "RatingDistributors",

  components: {
    MainObserver,
    DistributorRatingCard,
    MainLoader,
    RatingDistributorsTop,
    RatingDistributorFilters,
    DistributorCard,
  },

  data() {
    return {
      countOfDistributors: 50,
      isLoading: false,
      isShowPersonCard: true,
      position: "bottom",
      ratingType: "earnings",
      placeNumber: null,
    };
  },

  computed: {
    ...mapState({
      rating: (state) => state.rating.rating,
      userId: (state) => state.auth.user.core_person_id,
    }),

    ...mapGetters({
      getDistributors: "rating/getDistributors",
      getPlaceNumber: "rating/getPlaceNumber",
    }),
  },

  methods: {
    ...mapActions({
      loadRating: "rating/loadRating",
      loadTSPRating: "rating/loadTSPRating",
    }),

    updateRating: debounce(200, function (params, ratingType) {
      this.isLoading = true;
      this.ratingType = ratingType;

      if (ratingType === "earnings") {
        this.loadRating(params).finally(() => {
          this.isLoading = false;
          this.isShowPersonCard = false;
          this.placeNumber = null;
          this.countOfDistributors = 50;
        });
      } else {
        this.loadTSPRating(params).finally(() => {
          this.isLoading = false;
          this.isShowPersonCard = true;
          this.placeNumber = this.getPlaceNumber(this.userId, this.ratingType);
          this.countOfDistributors = 25;
        });
      }
    }),

    showPersonCard(position) {
      if (this.ratingType === "earnings") {
        return;
      }

      this.isShowPersonCard = true;
      this.position = position;
    },

    hidePersonCard() {
      this.isShowPersonCard = false;
    },

    changePosition(value) {
      if (value) {
        this.position = value;
        return;
      }

      this.position = this.position === "top" ? "bottom" : "top";
    },
  },
};
</script>

<template>
  <div class="rating-distributors default-content-padding">
    <RatingDistributorFilters
      class="rating-distributors__filters"
      @updateRating="updateRating"
    />

    <RatingDistributorsTop
      v-if="rating"
      class="rating-distributors__top"
      :date="rating.last_refreshed_at"
    />

    <MainLoader v-if="isLoading" />

    <div
      v-if="!isLoading && getDistributors(countOfDistributors).length !== 0"
      class="rating-distributors__list"
    >
      <DistributorCard
        v-for="(distributor, index) in getDistributors(countOfDistributors)"
        :key="distributor.distributor_id"
        :distributor="distributor"
        :index="index"
        @showPersonCard="showPersonCard"
        @hidePersonCard="hidePersonCard"
      />

      <MainObserver
        v-if="
          ratingType === 'invitingTsp' &&
          !!placeNumber &&
          placeNumber > 25 &&
          getDistributors(countOfDistributors).length > 3
        "
        @intersect="changePosition"
        @notIntersect="changePosition('bottom')"
      />
    </div>

    <p
      v-if="
        getDistributors(countOfDistributors).length === 0 &&
        !isLoading &&
        rating !== null
      "
      class="rating-distributors__notice"
    >
      По выбранным фильтрам дистрибьюторы не найдены
    </p>

    <DistributorRatingCard
      v-show="isShowPersonCard"
      :position="position"
      :number="placeNumber"
    />
  </div>
</template>

<style lang="scss" scoped>
.rating-distributors {
  position: relative;

  &__filters {
    margin-bottom: 32px;
  }

  &__top {
    margin-bottom: 32px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
    position: relative;
  }

  &__notice {
    @include body-1;
    color: $dark-primary;
  }
}

@media (min-width: 576px) {
  .rating-distributors {
    padding: 0 48px 24px;
  }
}

@media (min-width: 1024px) {
  .rating-distributors {
    max-width: 700px;
  }
}
</style>
