<script>
import { mapState } from "vuex";
import QualificationPreview from "@/components/QualificationPreview.vue";
import vClickOutside from "v-click-outside";
import MainObserver from "@/components/helpers/MainObserver.vue";

export default {
  name: "DistributorCard",

  emits: ["hidePersonCard", "showPersonCard"],
  components: { MainObserver, QualificationPreview },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    distributor: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    personalCard: {
      type: Boolean,
      default: false,
    },

    personalCardInRating: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isShowInfo: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    formattedImagePath() {
      return (
        this.distributor.avatar ?? require("../../assets/images/no_avatar.png")
      );
    },

    name() {
      return (
        this.distributor.full_name ??
        `${this.distributor.first_name} ${this.distributor.last_name}`
      );
    },
  },

  methods: {
    hideItem() {
      this.isShowInfo = false;
    },

    hidePersonCard() {
      this.$emit("hidePersonCard");
    },

    showPersonCard(position) {
      this.$emit("showPersonCard", position);
    },
  },
};
</script>

<template>
  <div
    v-click-outside="hideItem"
    class="distributor-card"
    :class="{ 'distributor-card_open': isShowInfo }"
    @click="isShowInfo = !isShowInfo"
  >
    <MainObserver
      v-if="user.core_person_id === distributor.person_id"
      @intersect="hidePersonCard"
      @notIntersect="showPersonCard"
    />

    <div class="distributor-card__avatar">
      <img
        class="distributor-card__img"
        :src="formattedImagePath"
        alt="avatar"
      />
      <span class="distributor-card__number">
        {{ index + 1 }}
      </span>
    </div>

    <div class="distributor-card__info">
      <p class="distributor-card__name">
        {{ name }}
      </p>

      <QualificationPreview
        :qualification-code="distributor.qualification_code"
      />

      <div
        v-if="distributor.trade_points_count"
        class="distributor-card__tsp-info"
      >
        <span class="icon-new-tsp"></span>
        <p>Подключено ТСП:</p>
        <span>{{ distributor.trade_points_count }}</span>
      </div>
    </div>

    <span
      v-if="distributor.trade_points_count"
      class="icon-arrow"
      :class="{ 'icon-arrow_open': isShowInfo }"
    />

    <div
      v-if="distributor.trade_points_count"
      class="distributor-card__tsp-stat tsp-stat"
      :class="{ 'tsp-stat_open': isShowInfo }"
    >
      <div class="tsp-stat__wrapper">
        <div v-if="distributor.lite_count" class="lite">
          <p>Лайт</p>
          <span>{{ distributor.lite_count }}</span>
        </div>

        <div v-if="distributor.promo_count" class="promo">
          <p>Промо</p>
          <span>{{ distributor.promo_count }}</span>
        </div>

        <div v-if="distributor.pluspro_count" class="pluspro">
          <p>Про+</p>
          <span>{{ distributor.pluspro_count }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.distributor-card {
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 76px auto 32px;
  align-items: center;
  border-radius: 8px;
  background-color: $light-primary;
  padding: 16px;
  transition: all 0.5s ease;
  height: fit-content;

  &_open {
    grid-template-rows: 1fr auto;
  }

  &__avatar {
    position: relative;
    width: fit-content;
    margin-right: 20px;
  }

  &__number {
    position: absolute;
    right: 50%;
    bottom: -9px;
    transform: translateX(50%);
    min-width: 18px;
    height: 18px;
    @include caption-1-bold;
    color: $dark-primary;
    border-radius: 10px;
    background: $light-third;
    border: 2px solid $light-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &:nth-child(1) {
    .distributor-card__number {
      background: linear-gradient(
          180deg,
          rgba(247, 229, 71, 0.2) 8.13%,
          rgba(254, 252, 175, 0.2) 20.67%,
          rgba(248, 220, 32, 0.2) 34.76%,
          rgba(230, 122, 11, 0.2) 52.27%,
          rgba(248, 220, 32, 0.2) 87.97%
        ),
        #ffda7a;
    }
  }

  &:nth-child(2) {
    .distributor-card__number {
      background: linear-gradient(
          180deg,
          rgba(200, 208, 229, 0.2) 7.93%,
          rgba(250, 250, 250, 0.2) 20.99%,
          rgba(200, 209, 226, 0.2) 34.91%,
          rgba(86, 107, 124, 0.2) 52.32%,
          rgba(169, 184, 207, 0.2) 88.01%
        ),
        #969696;
      color: $light-primary;
    }
  }

  &:nth-child(3) {
    .distributor-card__number {
      background: linear-gradient(
          180deg,
          rgba(237, 185, 102, 0.2) 8.12%,
          rgba(221, 220, 215, 0.2) 20.67%,
          rgba(233, 184, 125, 0.2) 34.76%,
          rgba(133, 58, 39, 0.2) 52.27%,
          rgba(211, 149, 76, 0.2) 87.99%
        ),
        #f4943b;
      color: $light-primary;
    }
  }

  &__img {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    min-width: 46px;
    height: 46px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__name {
    display: flex;
    @include body-1-bold;
    color: $dark-primary;
  }

  &__tsp-info {
    display: flex;
    gap: 4px;
    @include text-2;
    color: $dark-third;
    margin-top: 12px;

    p {
      white-space: nowrap;
    }
  }

  .tsp-stat {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0;
    grid-column: -1/1;
    grid-row: 2/2;
    transition: all 0.5s ease;
    opacity: 0;

    &_open {
      grid-template-rows: 38px;
      padding-top: 16px;
      opacity: 1;
    }

    &__wrapper {
      display: flex;
      gap: 4px;
      width: 100%;

      > div {
        width: 100%;
        transition: none;
        border-radius: 12px;
        display: flex;
        padding: 8px 12px;
        justify-content: space-between;
        align-items: center;
        color: $dark-primary;

        > p {
          @include caption-1;
        }

        > span {
          @include body-1-bold;
        }
      }
    }
  }
}

.lite {
  background: $light-third;
}

.promo {
  background: $gold-main;
}

.pluspro {
  background: $lavander-gradient;
  color: $light-primary !important;
}

.icon-new-tsp {
  width: 16px;
  height: 16px;
  background: $dark-fourth;
}

.qualification-preview {
  margin-top: 4px;
}

.icon-arrow {
  width: 32px;
  height: 32px;
  background: $dark-fifth;
  transition: all 0.3s ease;
  transform: rotate(180deg);
  margin-left: 8px;

  &_open {
    transform: rotate(0deg);
  }
}

.observer {
  position: absolute;
  z-index: 1;
}

@media (min-width: 375px) {
  .distributor-card {
    &__img {
      min-width: 56px;
      height: 56px;
    }
  }
}

@media (min-width: 1024px) {
  .qualification-preview {
    margin-top: 8px;
  }
}

@media (max-width: 319px) {
  .distributor-card {
    &__tsp-info {
      flex-wrap: wrap;
    }
  }
}
</style>
