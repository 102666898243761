<script>
import { mapState } from "vuex";

export default {
  name: "DistributorRatingCard",

  props: {
    position: {
      type: String,
      required: true,
    },

    number: {
      required: true,
    },
  },

  watch: {
    number() {
      console.log(this.number);
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      windowInnerWidth: (state) => state.innerWidth,
    }),

    formattedImagePath() {
      return this.user.avatar ?? require("../../assets/images/no_avatar.png");
    },

    style() {
      let pixels =
        this.windowInnerWidth < 1024 && this.position !== "bottom" ? 78 : 12;
      return `${this.position}: ${pixels}px`;
    },
  },
};
</script>

<template>
  <div v-if="number" class="distributor-rating-card" :style="style">
    <img :src="formattedImagePath" alt="avatar" />
    <p>Ваше место в рейтинге</p>
    <mark>{{ number }}</mark>
  </div>
</template>

<style lang="scss" scoped>
.distributor-rating-card {
  position: fixed;
  left: 0;
  z-index: 2;
  width: -webkit-fill-available;
  max-width: 628px;
  margin: 0 12px;
  display: flex;
  padding: 12px;
  align-items: center;
  background: $light-primary;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  height: fit-content;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
  }

  p {
    @include text-2-bold;
    color: $dark-primary;
    width: 100%;
    margin-right: 24px;
  }

  mark {
    padding: 14px 16px;
    border-radius: 2px;
    background: $light-third;
    @include body-1-bold;
    color: $dark-primary;
  }
}

@media (min-width: 576px) {
  .distributor-rating-card {
    margin: 0 36px;
  }
}

@media (min-width: 1024px) {
  .distributor-rating-card {
    width: calc(100vw - 402px);
    margin: 0 auto 0 366px;
    bottom: 32px;
  }
}

@media (min-width: 1440px) {
  .distributor-rating-card {
    width: 724px;
    margin: 0 auto 0 440px;
  }
}
</style>
